import { combineReducers } from 'redux';

// Import custom components
import productReducer from './products';
import cartReducer from './cart';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import filterReducer from './filter';
import modalReducer from './modal';
import authReducer from "./auth"
import SettingsReducer from "./settings"
import bannersReducer from "./banners"
import blogsReducer from "./blogs"
const rootReducer = combineReducers({
  auth: authReducer,
  data: productReducer,
  cartlist: cartReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  filters: filterReducer,
  modal: modalReducer,
  settings: SettingsReducer,
  banners: bannersReducer,
  blogs: blogsReducer,
});

export default rootReducer;