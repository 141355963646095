import React from "react";
import { Link } from "react-router-dom";

import { safeContent, isIEBrowser } from "../../utils";

function IntroSlide(props) {
  const {
    image,
    srcSet,
    sub_title,
    title,
    price,
    button_text,
    button_link,
  } = props.data;

  return (
    <div className="intro-slide">
      <figure className="slide-image">
        <picture style={isIEBrowser() ? { height: "100%" } : {}}>
          <source media="(max-width: 480px)" srcSet={srcSet} />
          <img
            src={image}
            alt="desc"
            style={isIEBrowser() ? { height: "100%" } : {}}
            width="780"
            height="400"
          />
        </picture>
      </figure>

      <div className="intro-content">
        <h3 className="intro-subtitle text-primary">{sub_title}</h3>
        <h1
          className="intro-title"
          dangerouslySetInnerHTML={safeContent(title)}
        ></h1>
        {/* <div
          className="intro-price"
          dangerouslySetInnerHTML={safeContent(price)}
        ></div> */}

        <Link
          to={`${process.env.PUBLIC_URL}/shop/sidebar/list`}
          className="btn btn-primary btn-round mt-4"
        >
          <span>{button_text ? button_text : "Start Shopping"}</span>
          <i className="icon-long-arrow-right"></i>
        </Link>
        {/* <div className="mt-3 text-white">
          <a
            href={`${button_link}`}
            target="_blank"
            className="btn btn-round"
            style={{ marginRight: "30px" }}
          >
            <span className="text-white">{button_link}</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default React.memo(IntroSlide);
