import { SETTINGS, LANGUAGES, STORES } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  settings: {},
  languages: [],
  stores: [],
};

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };

    case LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      };
    case STORES:
      return {
        ...state,
        stores: action.stores,
      };

    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: "molla-",
  key: "settings",
  storage,
};

export default persistReducer(persistConfig, settingsReducer);
