import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Search from "../components/search/Search";

import Layout from '../components/app';
import LoadingOverlay from "../components/features/loading-overlay";
import cart from "../components/pages/shop/cart";
import HomePage from '../components/home';

import { scrollTop } from "../utils";
import checkout from "../components/pages/shop/checkout";

const ElementPages = React.lazy(() => import("./elements-route.js"));
const ProductPages = React.lazy(() => import("./products-route.js"));
const ShopPages = React.lazy(() => import("./shop-route.js"));
const BlogPages = React.lazy(() => import("./blogs-route.js"));
const OtherPages = React.lazy(() => import("./others-route.js"));

export default function AppRoot() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <React.Suspense fallback={<LoadingOverlay />}>
      <Switch>
	   <Layout>
		<Route exact path={`/`} component={HomePage} />
        <Route
          path={`${process.env.PUBLIC_URL}/elements`}
          component={ElementPages}
        />
        <Route path={`/cart`} component={cart} />
        <Route path={`/checkout`} component={checkout} />
        <Route
          path={`${process.env.PUBLIC_URL}/product`}
          component={ProductPages}
        />
        <Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages} />
        <Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages} />
        <Route
          path={`${process.env.PUBLIC_URL}/pages`}
          component={OtherPages}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/search`}
          component={Search}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
       </Layout>
	  </Switch>
    </React.Suspense>
  );
}
