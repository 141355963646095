import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";

// the translations
// (tip move them in a JSON file and import them)

// console.log(window.localStorage.getItem('default_language'));
// console.log(window.localStorage.getItem('language'));
const resources = {
  en: {
    common: common_en, // 'common' is our custom namespace
  },
  ar: {
    common: common_ar,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.getItem("language"),
    fallbackLng:
      window.localStorage.getItem("default_language") !== null
        ? window.localStorage.getItem("default_language")
        : "en",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
