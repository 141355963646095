import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// import Custom Components
import PageHeader from "../../common/page-header";
import Breadcrumb from "../../common/breadcrumb";
import Accordion from "../../features/accordion/accordion";
import Card from "../../features/accordion/card";

import { getCartTotal } from "../../../services";
import { useTranslation } from "react-i18next";

function Checkout(props) {
  const { cartlist, total } = props;
  const { t, i18n } = useTranslation("common");

  const shippingPrice = { free: 0, standard: 10, express: 20 };
  const shippingObj = {
    free: "Free shipping",
    standard: "Standard",
    express: "Express",
  };

  useEffect(() => {
    let item = document.querySelector("#checkout-discount-input");

    var opactiyEffect = function(e) {
      e.currentTarget.parentNode
        .querySelector("label")
        .setAttribute("style", "opacity: 0");
    };

    var blurEffect = function(e) {
      let $this = e.currentTarget;
      if ($this.length !== 0) {
        $this.parentNode
          .querySelector("label")
          .setAttribute("style", "opacity: 0");
      } else {
        $this.parentNode
          .querySelector("label")
          .setAttribute("style", "opacity: 1");
      }
    };

    item.addEventListener("focus", opactiyEffect);

    item.addEventListener("blur", blurEffect);

    return () => {
      item.removeEventListener("focus", opactiyEffect);

      item.removeEventListener("blur", blurEffect);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Molla React eCommerce Template | Checkout</title>
      </Helmet>

      <h1 className="d-none">Molla React eCommerce Template - Checkout</h1>

      <div className="main">
        <PageHeader title={t("checkout.checkout")} subTitle={t("shop.shop")} />
        <Breadcrumb
          title={t("checkout.checkout")}
          parent1={[t("checkout.shop"), "shop/sidebar/list"]}
        />

        <div className="page-content">
          <div className="checkout">
            <div className="container">
              <div className="checkout-discount">
                <form action="#">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="checkout-discount-input"
                  />
                  <label
                    htmlFor="checkout-discount-input"
                    className="text-truncate"
                  >
                    {t("checkout.have_copon")}?{" "}
                    <span>{t("checkout.enter_code")}</span>
                  </label>
                </form>
              </div>

              <form action="#">
                <div className="row">
                  <div className="col-lg-9">
                    <h2 className="checkout-title">
                      {t("checkout.billing_details")}
                    </h2>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>{t("checkout.first_name")} *</label>
                        <input type="text" className="form-control" required />
                      </div>

                      <div className="col-sm-6">
                        <label>{t("checkout.last_name")} *</label>
                        <input type="text" className="form-control" required />
                      </div>
                    </div>

                    <label>{t("checkout.company_name")}</label>
                    <input type="text" className="form-control" />

                    <label>{t("checkout.country")} *</label>
                    <input type="text" className="form-control" required />

                    <label>{t("checkout.street_address")} *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("checkout.house_number_and_street_name")}
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("checkout.appartments_suite_unit")}
                      required
                    />

                    <div className="row">
                      <div className="col-sm-6">
                        <label>{t("checkout.town_city")}</label>
                        <input type="text" className="form-control" required />
                      </div>

                      <div className="col-sm-6">
                        <label>{t("checkout.state_county")} *</label>
                        <input type="text" className="form-control" required />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>{t("checkout.postcode_zip")} *</label>
                        <input type="text" className="form-control" required />
                      </div>

                      <div className="col-sm-6">
                        <label>{t("checkout.phone")}*</label>
                        <input type="tel" className="form-control" required />
                      </div>
                    </div>

                    <label>{t("checkout.email_address")}*</label>
                    <input type="email" className="form-control" required />

                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkout-create-acc"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkout-create-acc"
                      >
                        {t("checkout.create_account")}?
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkout-diff-address"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkout-diff-address"
                      >
                        {t("checkout.ship_address")}?
                      </label>
                    </div>

                    <label>{t("checkout.order_notes")}</label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="4"
                      placeholder={t("checkout.special_notes")}
                    ></textarea>
                  </div>

                  <aside className="col-lg-3">
                    <div className="summary">
                      <h3 className="summary-title">{t("checkout.order")}</h3>

                      <table className="table table-summary">
                        <thead>
                          <tr>
                            <th>{t("checkout.product")}</th>
                            <th>{t("checkout.the_total")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {cartlist.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Link to="#">{item.name}</Link>
                              </td>
                              <td>
                                $
                                {item.sum.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                          ))}
                          <tr className="summary-subtotal">
                            <td>{t("checkout.sub_total")}:</td>
                            <td>
                              $
                              {total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("checkout.the_shipping")}:</td>
                            <td>{shippingObj[props.shipping]}</td>
                          </tr>
                          <tr className="summary-total">
                            <td>{t("checkout.the_total")}:</td>
                            <td>
                              $
                              {(
                                total + shippingPrice[props.shipping]
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <Accordion type="checkout">
                        <Card title={t("checkout.direct_transfer")}>
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </Card>

                        <Card title={t("checkout.check_payments")}>
                          Ipsum dolor sit amet, consectetuer adipiscing elit.
                          Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis.
                        </Card>

                        <Card title={t("checkout.cash_delivery")}>
                          Quisque volutpat mattis eros. Lorem ipsum dolor sit
                          amet, consectetuer adipiscing elit. Donec odio.
                          Quisque volutpat mattis eros.
                        </Card>

                        <Card title="PayPal">
                          <small className="float-right paypal-link">
                            What is PayPal?
                          </small>
                          Nullam malesuada erat ut turpis. Suspendisse urna
                          nibh, viverra non, semper suscipit, posuere a, pede.
                          Donec nec justo eget felis facilisis fermentum.
                        </Card>

                        <Card title={t("checkout.the_credit_card")}>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/payments-summary.png`}
                            alt="payments cards"
                          />
                          Donec nec justo eget felis facilisis fermentum.Lorem
                          ipsum dolor sit amet, consectetuer adipiscing elit.
                          Donec odio. Quisque volutpat mattis eros. Lorem ipsum
                          dolor sit ame.
                        </Card>
                      </Accordion>

                      <button
                        type="submit"
                        className="btn btn-outline-primary-2 btn-order btn-block"
                      >
                        <span className="btn-text">{t("checkout.place_order")}</span>
                        <span className="btn-hover-text">
                       {t("checkout.proceed_the_checkout")}
                        </span>
                      </button>
                    </div>
                  </aside>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => ({
  cartlist: state.cartlist.cart,
  total: getCartTotal(state.cartlist.cart),
  shipping: state.cartlist.shipping,
});

export default connect(mapStateToProps)(Checkout);
