import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer( props ) {
      const {t} = useTranslation("common");
    const { logo = "assets/images/logo-footer.png", container = "container" } = props;

    return (
      <footer className="footer">
        <div className="footer-middle">
          <div className={container}>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="widget widget-about">
                  <img
                    src={process.env.PUBLIC_URL + "/" + logo}
                    className="footer-logo"
                    alt="Footer Logo"
                    width="105"
                    height="25"
                  />
                  <p>
                    Praesent dapibus, neque id cursus ucibus, tortor neque
                    egestas augue, eu vulputate magna eros eu erat.{" "}
                  </p>

                  <div className="widget-call">
                    <i className="icon-phone"></i>
                    {t("footer.got_questions")}? {t("footer.call_us")} 24/7
                    <Link to="tel:#">+0123 456 789</Link>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="widget">
                  <h4 className="widget-title">{t("footer.useful_links")}</h4>

                  <ul className="widget-list">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about`}>
                        {t("footer.about_molla")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.our_services")}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/pages/about`}>
                        {t("footer.how_to_shop_on_molla")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/pages/faq`}>
                        {t("footer.faq")}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                        {t("footer.contact_us")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="widget">
                  <h4 className="widget-title">
                    {t("footer.customer_service")}
                  </h4>

                  <ul className="widget-list">
                    <li>
                      <Link to="#">{t("footer.payment_method")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.money_back_guarantee")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.returns")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("cart.shipping")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.terms_conditions")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.privacy_policy")}</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="widget">
                  <h4 className="widget-title">{t("header.my_account")}</h4>

                  <ul className="widget-list">
                    <li>
                      <Link to="#">{t("user.signin")}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/shop/cart`}>
                        {t("header.view_cart")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/shop/wishlist`}>
                        {t("footer.my_wishlist")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.track_my_order")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.help")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className={container}>
            <p className="footer-copyright">
              Copyright © {new Date().getFullYear()} Molla Store. All Rights
              Reserved.
            </p>
            <figure className="footer-payments">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/payments.png`}
                alt="Payment methods"
                width="272"
                height="20"
              />
            </figure>
          </div>
        </div>
      </footer>
    );
}

export default React.memo( Footer );