import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// import Custom Components
import PageHeader from "../../common/page-header";
import Breadcrumb from "../../common/breadcrumb";

import { getCartTotal } from "../../../services";
import { quantityInputs, isIEBrowser } from "../../../utils";
import { changeQty, removeFromCart, changeShipping } from "../../../actions";
import { useTranslation } from "react-i18next";
import Axios from "axios";

function Cart(props) {
  const { t } = useTranslation("common");
  let token = localStorage.getItem("userToken");

  const { cartlist, total, removeFromCart, prevShip } = props;

  const [shipping, setShipping] = useState(prevShip);
  const shippingPrice = { free: 0, standard: 10, express: 20 };

  const [allCartData, setAllCartData] = useState([]);
  useEffect(() => {
    const getAllCartData = async () => {
      const response = await Axios.get("cart", {
        headers: { Authorization: `Bearer ${token}` },
        // body:{user}
      });
      setAllCartData(response.data.data);
    };
    getAllCartData();
  }, []);

  useEffect(() => {
    quantityInputs();
  });
  useEffect(() => {
    cartlist.map((item, index) => {
      if (
        document.querySelector(
          `#quantity-input-wrapper-${index} .input-group input`
        )
      ) {
        document.querySelector(
          `#quantity-input-wrapper-${index} .input-group input`
        ).value = item.qty;
      }
      return item;
    });
  }, [cartlist]);

  function onChangeShipping(val) {
    setShipping(val);
  }

  function onChangeQty(e, productId) {
    props.changeQty(
      productId,
      e.currentTarget.querySelector('input[type="number"]').value
    );
  }

  function goToCheckout() {
    props.changeShipping(shipping);
  }

  return (
    <>
      <Helmet>
        <title>Molla React eCommerce Template | Shopping Cart</title>
      </Helmet>

      <h1 className="d-none">Molla React eCommerce Template - Shopping Cart</h1>

      <div className="main">
        <PageHeader title={t("cart.shopping_cart")} subTitle={t("cart.shop")} />
        <Breadcrumb
          title={t("cart.shopping_cart")}
          parent1={["Shop", "shop/sidebar/list"]}
          //   parents={[{ text: t("cart.shop"), link: "shop" }]}
        />
        <div className="page-content">
          <div className="cart">
            <div className="container">
              <div className="row">
                <div className="col-lg-9">
                  <table className="table table-cart table-mobile">
                    <thead>
                      <tr>
                        <th>{t("cart.product")}</th>
                        <th>{t("cart.price")}</th>
                        <th>{t("cart.quantity")}</th>
                        <th>{t("cart.total")}</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartlist.length > 0 ? (
                        cartlist.map((item, index) => (
                          <tr key={"cart-item-" + index}>
                            <td className="product-col">
                              <div className="product">
                                <figure className="product-media">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/" +
                                        item.image
                                      }
                                      alt="Product"
                                    />
                                  </Link>
                                </figure>

                                <h3 className="product-title">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}
                                  >
                                    {item.name}
                                  </Link>
                                </h3>
                              </div>
                            </td>

                            <td className="price-col">
                              $
                              {0 < item.discount
                                ? item.salePrice.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : item.price.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </td>

                            <td
                              className="quantity-col"
                              id={"quantity-input-wrapper-" + index}
                            >
                              <div
                                className="cart-product-quantity"
                                onClick={(e) => onChangeQty(e, item.id)}
                              >
                                <input
                                  type="number"
                                  className="form-control"
                                  defaultValue={item.qty}
                                  min="1"
                                  max={item.stock}
                                  step="1"
                                  data-decimals="0"
                                  required
                                />
                              </div>
                            </td>

                            <td className="total-col">
                              $
                              {item.sum.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>

                            <td className="remove-col">
                              <button
                                className="btn-remove"
                                onClick={(e) => removeFromCart(item.id)}
                              >
                                <i className="icon-close"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <p className="pl-2 pt-1 pb-1">
                              {" "}
                              {t("cart.empty_cart")}{" "}
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="cart-bottom">
                    <div
                      className="cart-discount"
                      style={{ minHeight: isIEBrowser() ? "40px" : "auto" }}
                    >
                      <form action="#">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="coupon code"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-primary-2"
                              type="submit"
                            >
                              <i className="icon-long-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <button className="btn btn-outline-dark-2">
                      <span>UPDATE CART</span>
                      <i className="icon-refresh"></i>
                    </button>
                  </div>
                </div>
                <aside className="col-lg-3">
                  <div className="summary summary-cart">
                    <h3 className="summary-title">{t("cart.cart_total")}</h3>

                    <table className="table table-summary">
                      <tbody>
                        <tr className="summary-subtotal">
                          <td>{t("cart.subtotal")} :</td>
                          <td>
                            $
                            {total.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr className="summary-shipping">
                          {t("cart.shipping")} :<td>&nbsp;</td>
                        </tr>

                        <tr className="summary-shipping-row">
                          <td>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="free-shipping"
                                name="shipping"
                                className="custom-control-input"
                                onChange={(e) => onChangeShipping("free")}
                                defaultChecked={
                                  "free" === prevShip ? true : false
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="free-shipping"
                              >
                                Free Shipping
                              </label>
                            </div>
                          </td>
                          <td>$0.00</td>
                        </tr>

                        <tr className="summary-shipping-row">
                          <td>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="standard-shipping"
                                name="shipping"
                                className="custom-control-input"
                                onChange={(e) => onChangeShipping("standard")}
                                defaultChecked={
                                  "standard" === prevShip ? true : false
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="standard-shipping"
                              >
                                Standard:
                              </label>
                            </div>
                          </td>
                          <td>$10.00</td>
                        </tr>

                        <tr className="summary-shipping-row">
                          <td>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="express-shipping"
                                name="shipping"
                                className="custom-control-input"
                                onChange={(e) => onChangeShipping("express")}
                                defaultChecked={
                                  "express" === prevShip ? true : false
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="express-shipping"
                              >
                                Express:
                              </label>
                            </div>
                          </td>
                          <td>$20.00</td>
                        </tr>

                        <tr className="summary-shipping-estimate">
                          <td>
                            Estimate for Your Country
                            <br />{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/shop/dashboard`}
                            >
                              {t("cart.change_address")}
                            </a>
                          </td>
                          <td>&nbsp;</td>
                        </tr>

                        <tr className="summary-total">
                          <td>{t("cart.total")} :</td>
                          <td>
                            $
                            {(total + shippingPrice[shipping]).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <button
                      className="btn btn-outline-primary-2 btn-order btn-block"
                      onClick={goToCheckout}
                    >
                      {t("cart.proceed_checkout")}
                    </button>
                  </div>

                  <Link
                    to={`${process.env.PUBLIC_URL}/shop/sidebar/list`}
                    className="btn btn-outline-dark-2 btn-block mb-3"
                  >
                    <span>{t("cart.continue_shopping")}</span>
                    <i className="icon-refresh"></i>
                  </Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => ({
  cartlist: state.cartlist.cart,
  total: getCartTotal(state.cartlist.cart),
  prevShip: state.cartlist.shipping,
});

export default connect(mapStateToProps, {
  changeQty,
  removeFromCart,
  changeShipping,
})(Cart);
