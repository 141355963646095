import { LOGIN, LOGOUT } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  user: localStorage.getItem("user") ? localStorage.getItem("user") : {},
  is_logged_in: localStorage.getItem("userToken") ? true : false,
  token: localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.user,
        token: action.token,
        is_logged_in: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        token: null,
        is_logged_in: false,
      };

    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: "molla-",
  key: "auth",
  storage,
};

export default persistReducer(persistConfig, authReducer);
