import React, { useState } from "react";
import store from "../../store";
import { loginAction } from "../../actions";
import { Link } from "react-router-dom";

import axios from "axios";
import { useTranslation } from "react-i18next";
const Register = ({ closeModal }) => {
  const { t, i18n } = useTranslation("common");
  const inputs = {
    name: "",
    email: "",
    password: "",
    mobile: "",
  };

  const [user, setUser] = useState(inputs);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    signUp();
  };
 const signUp = async () => {
    setError("");
    setLoading(true);
    axios.post("register",user)
    .then(response=>{
      if(response.data.status !=200){
        setError(response.data.msg);
        setLoading(true);
        return;
      }
      store.dispatch(loginAction(response.data));
      closeModal();
      console.log("success")
    })
    .catch(err=>console.log(err))
  };
  return (
    <form onSubmit={submitHandler}>
      {error != "" ? (
        <div className="alert alert-danger mb-1">{error}</div>
      ) : (
        ""
      )}
      <div className="form-group">
        <label htmlFor="register-name">{t("user.your_name")} : *</label>
        <input
          type="text"
          className="form-control"
          id="register-name"
          name="name"
          required
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="register-email-2">
          {t("user.your_email_address")} *
        </label>
        <input
          type="email"
          className="form-control"
          id="register-email-2"
          name="email"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          value={user.email}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="register-mobile-2">{t("user.mobile")} *</label>
        <input
          id="register-mobile-2"
          type="number"
          className="form-control"
          onChange={(e) => setUser({ ...user, mobile: e.target.value })}
          value={user.mobile}
          name="mobile"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="register-password-2">{t("user.password")} *</label>
        <input
          type="password"
          className="form-control"
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          value={user.password}
          name="password"
          required
        />
      </div>

      <div className="form-footer">
        <button type="submit" className="btn btn-outline-primary-2">
          <span>{t("user.signup")}</span>
          <i className="icon-long-arrow-right"></i>
        </button>

        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="register-policy-2"
            required
          />
          <label className="custom-control-label" htmlFor="register-policy-2">
            {t("user.i_agree_to_the")}{" "}
            <Link to="#">{t("user.privacy_policy")}</Link> *
          </label>
        </div>
      </div>
    </form>
  );
};

export default Register;
