import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCartCount, getCartTotal } from "../../../services";
import { removeFromCart } from "../../../actions";
import { safeContent } from "../../../utils";
import { useTranslation } from "react-i18next";

import axios from "axios";

function CartMenu(props) {
  const { t, i18n } = useTranslation("common");
  const { cartlist, removeFromCart } = props;
  let total = getCartTotal(cartlist);

  useEffect(() => {
    sendData();
  }, [cartlist]);

  const sendData = async () => {
    let params = {};
    params.cart = cartlist;
    const response = await axios.post("cart/update", params);
  };
  let token = localStorage.getItem("userToken");

  return (
    <div className="dropdown cart-dropdown">
      <Link
        to={`/cart`}
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
      >
        <div className="icon">
          <i className="icon-shopping-cart"></i>
          {token && getCartCount(cartlist) > 0 ? (
            <span className="cart-count">{getCartCount(cartlist)}</span>
          ) : (
            ""
          )}
        </div>

        <p>{t("header.cart")}</p>
      </Link>

      <div
        className={`dropdown-menu dropdown-menu-right ${
          cartlist.length === 0 ? "text-center" : ""
        }`}
      >
        {0 === cartlist.length ? (
          <p>No products in the cart.</p>
        ) : (
          <>
            <div className="dropdown-cart-products">
              {cartlist.map((item, index) => (
                <div className="product" key={index}>
                  <div className="product-cart-details">
                    <h4 className="product-title">
                      <Link
                        to={`/product/${item.id}`}
                        dangerouslySetInnerHTML={safeContent(item.name)}
                      ></Link>
                    </h4>

                    <span className="cart-product-info">
                      <span className="cart-product-qty">{item.qty}</span>x{" "}
                      {localStorage.getItem("currency")}{" "}
                      {item.discount
                        ? item.salePrice.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : item.price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </span>
                  </div>

                  <figure className="product-image-container">
                    <Link to={`/product/${item.id}`} className="product-image">
                      <img
                        src={item.image}
                        data-oi={item.image}
                        alt="product"
                      />
                    </Link>
                  </figure>
                  <button
                    className="btn-remove"
                    title="Remove Product"
                    onClick={() => removeFromCart(item.id)}
                  >
                    <i className="icon-close"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="dropdown-cart-total">
              <span>{t("header.total")}</span>

              <span className="cart-total-price">
                {localStorage.getItem("currency")}{" "}
                {total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>

            <div className="dropdown-cart-action">
              <Link to={`/cart`} className="btn btn-primary">
                {t("header.view_cart")}
              </Link>
              <Link to={`/checkout`} className="btn btn-outline-primary-2">
                <span>{t("header.checkout")}</span>
                <i className="icon-long-arrow-right"></i>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartlist: state.cartlist.cart ? state.cartlist.cart : [],
  };
}

export default connect(mapStateToProps, { removeFromCart })(CartMenu);
