import React, { useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import Modal from "react-modal";

import { closeModal } from "../../actions";
import Login from "./Login";
import Register from "./Register";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(77,77,77,0.6)",
    zIndex: "10000",
  },
};

Modal.setAppElement("#root");

function LoginModal(props) {
    const { t, i18n } = useTranslation("common");
  const { showModal, modal } = props;
  let timer;

  function closeModal() {
    document
      .getElementById("login-modal")
      .classList.remove("ReactModal__Content--after-open");

    timer = setTimeout(() => {
      props.closeModal("login");
    }, 200);
  }

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  return (
    <Modal
      isOpen={showModal && "login" === modal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Login Modal"
      className="modal-dialog modal-dialog-centered"
      id="login-modal"
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">
              <i className="icon-close"></i>
            </span>
          </button>
          <div className="form-box">
            <div className="form-tab">
              <Tabs selectedTabClassName="show" defaultIndex={0}>
                <TabList className="nav nav-pills nav-fill">
                  <Tab className="nav-item">
                    <span className="nav-link">{t("user.signin")}</span>
                  </Tab>

                  <Tab className="nav-item">
                    <span className="nav-link">{t("user.register")}</span>
                  </Tab>
                </TabList>

                <div className="tab-content">
                  <TabPanel style={{ paddingTop: "2rem" }}>
                    <Login closeModal={closeModal} />
                  </TabPanel>

                  <TabPanel>
                    <Register closeModal={closeModal} />
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    showModal: state.modal.showModal,
    modal: state.modal.modal,
  };
}

export default connect(mapStateToProps, { closeModal })(LoginModal);
