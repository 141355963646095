import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const { t } = useTranslation("common");
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const [q, setQ] = useState("");
  useEffect(() => {
    if (params.get("q") !== "") setQ(params.get("q"));
  }, []);
  return (
    <div className="header-center">
      <div
        className="header-search header-search-extended header-search-visible d-none d-lg-block"
        style={{ marginRight: 0 }}
      >
        <Link to="#" className="search-toggle" role="button">
          <i className="icon-search"></i>
        </Link>
        <form action="search" method="get">
          <div className="header-search-wrapper search-wrapper-wide">
            <label htmlFor="q" className="sr-only">
              Search
            </label>
            <button className="btn btn-primary" type="submit">
              <i className="icon-search"></i>
            </button>
            <input
              placeholder={t("header.search_products")}
              type="search"
              className="form-control"
              name="q"
              id="q"
              required
              onChange={(e) => setQ(e.target.value)}
              value={q}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Search);
