import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { I18nextProvider } from "react-i18next";
import "react-app-polyfill/ie11";

import { PersistGate } from "redux-persist/integration/react";

// env
import { env } from "./env";

// import store
import store, { persistor } from "./store";

// import action
import { getAllProducts, refreshStore } from "./actions";

// import routes
import AppRoute from "./routes";

// import Utils
import { initFunctions } from "./utils";

import LoadingOverlay from "./components/features/loading-overlay";
import i18n from "./i18n";

axios.defaults.baseURL = env.API_URL;
if (localStorage.getItem("userToken")) {
  let token = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
if (localStorage.getItem("language")) {
  axios.defaults.headers.common["lang"] = window.localStorage.getItem(
    "language"
  );
}
if (localStorage.getItem("store_id")) {
  axios.defaults.headers.common["store-id"] = window.localStorage.getItem(
    "store_id"
  );
}

export function Root() {
  initFunctions();
  store.dispatch(getAllProducts());

  useEffect(() => {
    if (store.getState().modal.current !== 3) {
      store.dispatch(refreshStore(3));
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={"/"}>
            <AppRoute />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
