import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import Custom Components
import MainMenu from "./partials/main-menu";
import CartMenu from "./partials/cart-menu";
import CategoryMenu from "./partials/category-menu";
import CompareMenu from "./partials/compare-menu";
import LoginModal from "../auth/login-modal";

import { logout, showModal, refreshStore } from "../../actions";
import auth from "../../reducers/auth";
import Search from "./partials/Search";

function Header(props) {
  const { t, i18n } = useTranslation("common");
  let token = localStorage.getItem("userToken");
  const { container = "container", auth, languages,stores } = props;

  function openLoginModal(e) {
    props.showModal("login");
    e.preventDefault();
  }
  function changeLanguage(language) {
    i18n.changeLanguage(language);
    window.localStorage.setItem("language", language);
    console.log("language0000000000", language);
    window.location.reload();
  }
    function changeStore(store_id) {
      window.localStorage.setItem("store_id", store_id);
      window.location.reload();
    }

    function findDefaultStore() {
      // console.log(localStorage.getItem("store_id"))
      let store = stores.find(
        (item) => item.id === parseInt(localStorage.getItem("store_id"))
      );
      // console.log(store);
      if (!store) {
        window.localStorage.setItem("currency", "EGP");
        store = { id: "1", name: "Egypt" };
        return store;
      }
      let currency = store.currency.symbol;
      // console.log(currency);
      window.localStorage.setItem("currency", currency);
      return store;
    }
  return (
    <header className="header header-intro-clearance header-3">
      <div className="header-top">
        <div className={container}>
          <div className="header-left">
            <Link to="tel:#">
              <i className="icon-phone"></i>
              {t("header.call")}: +0123 456 789
            </Link>
          </div>

          <div className="header-right">
            <ul className="top-menu">
              <li>
                <Link to="#">Links</Link>
                <ul>
                  <li>
                    {/* <div className="header-dropdown">
                      <Link to="#">USD</Link>
                      <div className="header-menu">
                        <ul>
                          <li>
                            <Link to="#">Eur</Link>
                          </li>
                          <li>
                            <Link to="#">Usd</Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="header-dropdown">
                      <Link to="#">{findDefaultStore().name}</Link>
                      <div className="header-menu">
                        <ul>
                          {stores.map((item, index) => (
                            <li key={index}>
                              <Link onClick={() => changeStore(item.id)} to="#">
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="header-dropdown">
                      <Link to="#">{i18next.language}</Link>
                      <div className="header-menu">
                        <ul>
                          {languages.map((item, index) => (
                            <li key={index}>
                              <Link
                                onClick={() => changeLanguage(item.symbol)}
                                to="#"
                              >
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li>
                    {auth.is_logged_in ? (
                      <Link
                        to="#logout"
                        onClick={(e) => {
                          e.preventDefault();
                          props.logout();
                          props.refreshStore();
                        }}
                      >
                        {t("header.logout")}
                      </Link>
                    ) : (
                      <Link
                        to="#signin-modal"
                        data-toggle="modal"
                        onClick={openLoginModal}
                      >
                        {t("header.login")} / {t("header.signup")}
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="header-middle">
        <div className={container}>
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                alt="Molla Logo"
                width={105}
                height={25}
              />
            </Link>
          </div>
          <div className="header-center" style={{ width: "57%" }}>
            <Search />
          </div>
          <div className="header-right">
            <CompareMenu />

            <div className="wishlist">
              <Link
                to={`${process.env.PUBLIC_URL}/shop/wishlist`}
                title="Wishlist"
              >
                <div className="icon">
                  <i className="icon-heart-o"></i>
                  {token && props.isWishlist.length > 0 ? (
                    <span className="wishlist-count badge">
                      {props.isWishlist.length}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <p>{t("header.wishlist")}</p>
              </Link>
            </div>

            <CartMenu />
          </div>
        </div>
      </div>

      <div className="header-bottom sticky-header">
        <div className={container}>
          <div className="header-left">
            <CategoryMenu type={3} />
          </div>

          <div className="header-center">
            <MainMenu />
          </div>

          <div className="header-right">
            <i className="la la-lightbulb-o"></i>
            <p>
              Clearance<span className="highlight">&nbsp;Up to 30% Off</span>
            </p>
          </div>
        </div>
      </div>
      <LoginModal />
    </header>
  );
}

function mapStateToProps(state) {
  return {
    isWishlist: state.wishlist.list,
    auth: state.auth,
    languages: state.settings.languages,
    settings: state.settings.settings,
    stores: state.settings.stores,
  };
}

export default connect(mapStateToProps, { showModal, logout, refreshStore })(
  Header
);
