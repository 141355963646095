import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CategoryMenu(props) {
  const [categories, setCategories] = useState([]);
  const { type = 1 } = props;
  const { t } = useTranslation("common");
  useEffect(() => {
    const getCategoriesData = async () => {
      const response = await axios.get("home/categories");
      setCategories(response.data.data);
    };
    getCategoriesData();
  }, []);
    function onShowMenu(e) {
      if (
        !document
          .querySelector(".category-dropdown.is-on")
          .classList.contains("show")
      ) {
        document
          .querySelector(".category-dropdown.is-on")
          .classList.add("show");
        document
          .querySelector(".category-dropdown.is-on > .dropdown-menu")
          .classList.add("show");
      } else {
        document
          .querySelector(".category-dropdown.is-on")
          .classList.remove("show");
        document
          .querySelector(".category-dropdown.is-on > .dropdown-menu")
          .classList.remove("show");
      }
      e.preventDefault();
    }

  return (
    <div className="dropdown category-dropdown">
      <Link
        // to={`${process.env.PUBLIC_URL}/shop/sidebar/list`}
        to="#"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
        title="Browse Categories"
      >
        {t("header.browse_categories")}
        {type === 3 ? <i className="icon-angle-down"></i> : ""}
      </Link>

      <div className="dropdown-menu">
        <nav className="side-nav">
          <ul className="menu-vertical sf-arrows sf-js-enabled">
            {categories.map((category) =>
              category.children.length > 0 ? (
                <li key={category.id} className="megamenu-container">
                  <Link className="sf-with-ul" to={`/category/${category.id}`}>
                    {category.name}
                  </Link>

                  <div className="megamenu">
                    <div className="row no-gutters">
                      <div className="col-md-7">
                        <div className="menu-col">
                          <div className="row">
                            <div className="col-md-6">
                              <ul>
                                {category.children.map((category) => (
                                  <li key={category.id}>
                                    <Link to={`/category/${category.id}`}>
                                      {category.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ width: "280px", height: "440px" }}
                        className="col-md-5"
                      >
                        <div className="banner banner-overlay">
                          <Link
                            to={`/shop/sidebar/list`}
                            className="banner banner-menu"
                          >
                            <img src={category.menu_image} alt="Banner" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <li key={category.id}>
                  <Link to={`/category/${category.id}`}>{category.name}</Link>
                </li>
              )
            )}
          </ul>
        </nav>{" "}
      </div>
    </div>
  );
}

export default React.memo(CategoryMenu);
