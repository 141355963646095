import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { closeModal, loginAction } from "../../actions";
import store from "../../store";
import { useTranslation } from "react-i18next";
const login = ({ closeModal }) => {
  	const { t, i18n } = useTranslation('common');
  const inputs = {
    email: "",
    password: "",
  };
  const [user, setUser] = useState(inputs);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    signIn();
  };
  const signIn = () => {
    setError("");
    setLoading(true)
    axios.post("login", user).then((response) => {
      if (response.status !== 200) {
        setError(response.data.msg);
        return;
      }
      store.dispatch(loginAction(response.data));
      closeModal();
      console.log("success");
    });
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="form-group">
          <label htmlFor="singin-email-2">{t("user.email_address")} *</label>
          <input
            type="text"
            className="form-control"
            id="singin-email-2"
            name="email"
            required
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            value={user.email}
          />
        </div>

        <div className="form-group">
          <label htmlFor="singin-password-2">{t("user.password")} *</label>
          <input
            type="password"
            className="form-control"
            id="singin-password-2"
            name="password"
            required
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            value={user.password}
          />
        </div>

        <div className="form-footer">
          <button type="submit" className="btn btn-outline-primary-2">
            <span>{t("user.login")}</span>
            {loading ? (
              <i className="icon-refresh load-more-rotating"></i>
            ) : (
              <i className="icon-long-arrow-right"></i>
            )}
          </button>

          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="signin-remember-2"
            />
            <label className="custom-control-label" htmlFor="signin-remember-2">
              {t("user.remember_me")}
            </label>
          </div>

          <Link to="#" className="forgot-link">
            {t("user.forgot_your_password")}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default login;
