import {
  GET_BANNERS,
} from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  banners: {},
};

const bannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };

    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "molla-",
  key: "banners",
  storage,
};

export default persistReducer(persistConfig, bannersReducer);
