import { GET_BLOGS } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = [];
const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "molla-",
  key: "blogs",
  storage,
};

export default persistReducer(persistConfig, blogsReducer);
